// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contribution-tsx": () => import("./../../../src/pages/contribution.tsx" /* webpackChunkName: "component---src-pages-contribution-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-notice-tsx": () => import("./../../../src/pages/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-notice-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-statistics-tsx": () => import("./../../../src/pages/statistics.tsx" /* webpackChunkName: "component---src-pages-statistics-tsx" */),
  "component---src-pages-styleguide-charts-tsx": () => import("./../../../src/pages/styleguide/charts.tsx" /* webpackChunkName: "component---src-pages-styleguide-charts-tsx" */),
  "component---src-pages-styleguide-colors-tsx": () => import("./../../../src/pages/styleguide/colors.tsx" /* webpackChunkName: "component---src-pages-styleguide-colors-tsx" */),
  "component---src-pages-styleguide-index-tsx": () => import("./../../../src/pages/styleguide/index.tsx" /* webpackChunkName: "component---src-pages-styleguide-index-tsx" */),
  "component---src-pages-styleguide-key-tsx": () => import("./../../../src/pages/styleguide/key.tsx" /* webpackChunkName: "component---src-pages-styleguide-key-tsx" */),
  "component---src-pages-styleguide-lorem-ipsum-tsx": () => import("./../../../src/pages/styleguide/lorem-ipsum.tsx" /* webpackChunkName: "component---src-pages-styleguide-lorem-ipsum-tsx" */),
  "component---src-pages-styleguide-typography-tsx": () => import("./../../../src/pages/styleguide/typography.tsx" /* webpackChunkName: "component---src-pages-styleguide-typography-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-typewriter-tsx": () => import("./../../../src/pages/typewriter.tsx" /* webpackChunkName: "component---src-pages-typewriter-tsx" */)
}


import { Keyboard } from '@types';
import { getOperationSystem, DEFAULT_SPEACER } from '@utils';

const os = getOperationSystem();
const osLabel = os.sign;

// TODO - currently only one Keyboard layout is supported. In case the user can log in on multiple devices, multiple layout should be supported, e.g.:
// layouts = {
//   default: keyboardLayout1...,
//   userDefined1: keyboardLayout1...,
// }

const keyboard: Keyboard = {
  name: 'Layout1',
  keys: [
    {
      iso: 'A99',
      hand: 'left',
      finger: 'thumb',
      code: 'ControlLeft',
      label: 'Ctrl',
    },
    {
      iso: 'A00',
      hand: 'left',
      finger: 'thumb',
      code: 'WakeUp',
      label: 'Fn',
      optional: true,
    },
    {
      iso: os.name === 'MacOS' ? 'A02' : 'A01',
      hand: 'left',
      finger: 'thumb',
      code: 'OSLeft',
      label: osLabel,
    },
    {
      iso: os.name === 'MacOS' ? 'A01' : 'A02',
      hand: 'left',
      finger: 'thumb',
      code: 'AltLeft',
      label: os.name === 'MacOS' ? '⌥' : 'Alt',
    },
    {
      iso: 'A03',
      hand: 'left',
      finger: 'thumb',
      code: 'Lang2',
      label: '한자', // hanja
      optional: true,
    },
    {
      iso: 'A03',
      hand: 'left',
      finger: 'thumb',
      code: 'NonConvert',
      label: '無変換', // muhenkan
      optional: true,
    },
    {
      iso: 'A04',
      hand: 'left',
      finger: 'thumb',
      code: 'Space',
      label: ' ',
      keyTops: {
        to: {
          label: ' ',
        },
      },
    },
    {
      iso: 'A06',
      hand: 'left',
      finger: 'thumb',
      code: 'Convert',
      label: '変換', // henkan
      optional: true,
    },
    {
      iso: 'A07',
      hand: 'left',
      finger: 'thumb',
      code: 'Lang1',
      label: '한/영', // han/yeong
      optional: true,
    },
    {
      iso: 'A07',
      hand: 'left',
      finger: 'thumb',
      code: 'KanaMode',
      label:
        '<tspan x="880" dy="-1.5em">カタカナ</tspan><tspan x="880" dy="1.2em">ひらがな</tspan><tspan x="880" dy="1.2em">ローマ字</tspan>', // katakana/hiragana/romaji
      optional: true,
    },
    {
      iso: os.name === 'MacOS' ? 'A10' : 'A08',
      hand: 'right',
      finger: 'thumb',
      code: 'AltRight',
      label: os.name === 'MacOS' ? '⌥' : 'Alt Gr',
    },
    {
      iso: 'A09',
      hand: 'left',
      finger: 'thumb',
      code: '',
      optional: true,
    },
    {
      iso: os.name === 'MacOS' ? 'A08' : 'A10',
      hand: 'left',
      finger: 'thumb',
      code: 'OSRight',
      label: osLabel,
      optional: true,
    },
    {
      iso: 'A11',
      hand: 'left',
      finger: 'thumb',
      code: 'ContextMenu',
      label: 'Menu',
      optional: true,
    },
    {
      iso: 'A12',
      hand: 'left',
      finger: 'thumb',
      code: 'ControlRight',
      label: 'Ctrl',
    },
    {
      iso: 'B99',
      hand: 'left',
      finger: 'little',
      code: 'ShiftLeft',
      label: '⇧',
    },
    {
      iso: 'B00',
      hand: 'left',
      finger: 'little',
      code: 'IntlBackslash',
      optional: true,
    },
    {
      iso: 'B01',
      hand: 'left',
      finger: 'little',
      code: 'KeyZ',
    },
    {
      iso: 'B02',
      hand: 'left',
      finger: 'ring',
      code: 'KeyX',
    },
    {
      iso: 'B03',
      hand: 'left',
      finger: 'middle',
      code: 'KeyC',
    },
    {
      iso: 'B04',
      hand: 'left',
      finger: 'index',
      code: 'KeyV',
    },
    {
      iso: 'B05',
      hand: 'left',
      finger: 'index',
      code: 'KeyB',
    },
    {
      iso: 'B06',
      hand: 'right',
      finger: 'index',
      code: 'KeyN',
    },
    {
      iso: 'B07',
      hand: 'right',
      finger: 'index',
      code: 'KeyM',
    },
    {
      iso: 'B08',
      hand: 'right',
      finger: 'middle',
      code: 'Comma',
    },
    {
      iso: 'B09',
      hand: 'right',
      finger: 'ring',
      code: 'Period',
    },
    {
      iso: 'B10',
      hand: 'right',
      finger: 'little',
      code: 'Slash',
    },
    {
      iso: 'B11',
      hand: 'right',
      finger: 'little',
      code: 'IntlRo',
      optional: true,
    },
    {
      iso: 'B12',
      hand: 'right',
      finger: 'little',
      code: 'ShiftRight',
      label: '⇧',
    },
    {
      iso: 'C00',
      hand: 'left',
      finger: 'little',
      code: 'CapsLock',
      label: 'Caps Lock',
    },
    {
      iso: 'C01',
      hand: 'left',
      finger: 'little',
      code: 'KeyA',
    },
    {
      iso: 'C02',
      hand: 'left',
      finger: 'ring',
      code: 'KeyS',
    },
    {
      iso: 'C03',
      hand: 'left',
      finger: 'middle',
      code: 'KeyD',
    },
    {
      iso: 'C04',
      hand: 'left',
      finger: 'index',
      code: 'KeyF',
    },
    {
      iso: 'C05',
      hand: 'left',
      finger: 'index',
      code: 'KeyG',
    },
    {
      iso: 'C06',
      hand: 'right',
      finger: 'index',
      code: 'KeyH',
    },
    {
      iso: 'C07',
      hand: 'right',
      finger: 'index',
      code: 'KeyJ',
      keyTops: {
        to: {
          toLearn: true,
        },
      },
    },
    {
      iso: 'C08',
      hand: 'right',
      finger: 'middle',
      code: 'KeyK',
    },
    {
      iso: 'C09',
      hand: 'right',
      finger: 'ring',
      code: 'KeyL',
    },
    {
      iso: 'C10',
      hand: 'right',
      finger: 'little',
      code: 'Semicolon',
    },
    {
      iso: 'C11',
      hand: 'right',
      finger: 'little',
      code: 'Quote',
    },
    {
      iso: 'C13',
      hand: 'right',
      finger: 'little',
      code: 'Enter',
      label: '↵',
      keyTops: {
        to: {
          label: '\n',
        },
      },
    },
    {
      iso: 'D00',
      hand: 'left',
      finger: 'little',
      code: 'Tab',
      label: '↹',
    },
    {
      iso: 'D01',
      hand: 'left',
      finger: 'little',
      code: 'KeyQ',
    },
    {
      iso: 'D02',
      hand: 'left',
      finger: 'ring',
      code: 'KeyW',
    },
    {
      iso: 'D03',
      hand: 'left',
      finger: 'middle',
      code: 'KeyE',
    },
    {
      iso: 'D04',
      hand: 'left',
      finger: 'index',
      code: 'KeyR',
    },
    {
      iso: 'D05',
      hand: 'left',
      finger: 'index',
      code: 'KeyT',
    },
    {
      iso: 'D06',
      hand: 'right',
      finger: 'index',
      code: 'KeyY',
    },
    {
      iso: 'D07',
      hand: 'right',
      finger: 'index',
      code: 'KeyU',
    },
    {
      iso: 'D08',
      hand: 'right',
      finger: 'middle',
      code: 'KeyI',
    },
    {
      iso: 'D09',
      hand: 'right',
      finger: 'ring',
      code: 'KeyO',
    },
    {
      iso: 'D10',
      hand: 'right',
      finger: 'little',
      code: 'KeyP',
    },
    {
      iso: 'D11',
      hand: 'right',
      finger: 'little',
      code: 'BracketLeft',
    },
    {
      iso: 'D12',
      hand: 'right',
      finger: 'little',
      code: 'BracketRight',
    },
    {
      iso: 'D13',
      hand: 'right',
      finger: 'little',
      code: 'Backslash',
      optional: true,
    },
    {
      iso: 'E00',
      hand: 'left',
      finger: 'little',
      code: 'Backquote',
    },
    {
      iso: 'E01',
      hand: 'left',
      finger: 'little',
      code: 'Digit1',
    },
    {
      iso: 'E02',
      hand: 'left',
      finger: 'little',
      code: 'Digit2',
    },
    {
      iso: 'E03',
      hand: 'left',
      finger: 'ring',
      code: 'Digit3',
    },
    {
      iso: 'E04',
      hand: 'left',
      finger: 'middle',
      code: 'Digit4',
    },
    {
      iso: 'E05',
      hand: 'left',
      finger: 'index',
      code: 'Digit5',
    },
    {
      iso: 'E06',
      hand: 'left',
      finger: 'index',
      code: 'Digit6',
    },
    {
      iso: 'E07',
      hand: 'right',
      finger: 'index',
      code: 'Digit7',
    },
    {
      iso: 'E08',
      hand: 'right',
      finger: 'index',
      code: 'Digit8',
    },
    {
      iso: 'E09',
      hand: 'right',
      finger: 'middle',
      code: 'Digit9',
    },
    {
      iso: 'E10',
      hand: 'right',
      finger: 'ring',
      code: 'Digit0',
    },
    {
      iso: 'E11',
      hand: 'right',
      finger: 'little',
      code: 'Minus',
    },
    {
      iso: 'E12',
      hand: 'right',
      finger: 'little',
      code: 'Equal',
    },
    {
      iso: 'E13',
      hand: 'right',
      finger: 'little',
      code: 'IntlYen',
      optional: true,
    },
    {
      iso: 'E14',
      hand: 'right',
      finger: 'little',
      code: 'Backspace',
      label: '⟵',
    },
  ],
  os,
  displayedLevel: 'to',

  keyMap: { ' ': { index: 6, level: 'to' }, '\n': { index: 41, level: 'to' } },
  codeMap: [
    'ControlLeft',
    'WakeUp',
    'OSLeft',
    'AltLeft',
    'Lang2',
    'NonConvert',
    'Space',
    'Convert',
    'Lang1',
    'KanaMode',
    'AltRight',
    '',
    'OSRight',
    'ContextMenu',
    'ControlRight',
    'ShiftLeft',
    'IntlBackslash',
    'KeyZ',
    'KeyX',
    'KeyC',
    'KeyV',
    'KeyB',
    'KeyN',
    'KeyM',
    'Comma',
    'Period',
    'Slash',
    'IntlRo',
    'ShiftRight',
    'CapsLock',
    'KeyA',
    'KeyS',
    'KeyD',
    'KeyF',
    'KeyG',
    'KeyH',
    'KeyJ',
    'KeyK',
    'KeyL',
    'Semicolon',
    'Quote',
    'Enter',
    'Tab',
    'KeyQ',
    'KeyW',
    'KeyE',
    'KeyR',
    'KeyT',
    'KeyY',
    'KeyU',
    'KeyI',
    'KeyO',
    'KeyP',
    'BracketLeft',
    'BracketRight',
    'Backslash',
    'Backquote',
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
    'Digit0',
    'Minus',
    'Equal',
    'IntlYen',
    'Backspace',
  ],
  allChars: [
    {
      glyph: DEFAULT_SPEACER,
      correct: 0,
      miswrite: 0,
      misread: 0,
    },
  ],
  layout: '101/104-ANSI',
  deadKeys: {},
};

export default keyboard;
